import React, { Component } from "react"
import anime from "animejs/lib/anime.es.js"
import handleViewport from "react-in-viewport"
import Image from "gatsby-image"
import writtenNumber from "written-number"
import styles from "./image-grid-two.module.scss"
class ImageGrid extends Component {
  constructor(props) {
    super(props)
    this.state = {
      active: null,
      images: null,
      amount: 0,
      loading: true,
      amountLoaded: 0,
      transitionLoad: null,
      windowWidth: null,
      imageOneWidth: [766, 1000],
      twoLeftValue: 192,
      threeTopValue: 100,
      twoTopValue: -348,
    }
  }
  componentDidMount() {
    this.doTransitions()

    if (typeof window !== `undefined`) {
      this.setWindowSize()
      this.calculateGridHeight()
      window.addEventListener("resize", this.setWindowSize)
    }
  }

  doTransitions = () => {
    const images = this.refs.grid.querySelectorAll(`.${styles.image}`)
    this.setState({
      images: images,
    })

    const transitionLoad = anime.timeline({
      easing: "easeInOutCirc(1, .5)",
      duration: 900,
      autoplay: false,
    })

    transitionLoad
      .add({
        targets: this.refs.item_0,
        duration: 200,
        translateX: ["-88px", "0px"],
        opacity: [0, 1],
      })
      .add(
        {
          targets: this.refs.item_1,
          duration: 200,
          translateX: ["88px", "0px"],
          opacity: [0, 1],
        },
        200
      )
      .add(
        {
          targets: this.refs.item_2,
          duration: 200,
          translateX: ["-88px", "0px"],
          opacity: [0, 1],
        },
        400
      )
    this.setState({
      transitionLoad,
    })
  }

  componentWillUnmount() {
    if (typeof window !== `undefined`) {
      window.removeEventListener("resize", this.setWindowSize)
    }
  }

  setWindowSize = () => {
    if (typeof window !== `undefined`) {
      this.setState({
        windowWidth: window.innerWidth,
      })
      this.calculateGridHeight()
    }
  }

  hoverIn = event => {
    if (this.state.windowWidth > 1024) {
      const hoveredElement =
        parseInt(event.currentTarget.getAttribute("data-ref")) + 1
      const number =
        writtenNumber(hoveredElement)
          .charAt(0)
          .toUpperCase() + writtenNumber(hoveredElement).slice(1)

      this.setState({
        active: `Item${number}`,
      })
      setTimeout(() => {
        this.calculateGridHeight()
      }, 500)
    }
  }

  hoverOut = () => {
    if (this.state.windowWidth > 1024) {
      this.setState({
        active: null,
      })
      setTimeout(() => {
        this.calculateGridHeight()
      }, 500)
    }
  }

  handleImageLoaded = () => {
    this.setState({
      amountLoaded: this.state.amountLoaded + 1,
    })
  }

  calculateGridHeight = () => {
    let width = 0
    let marginBottom = 100
    if (typeof window !== `undefined`) {
      width = window.innerWidth
    }
    if (width > 1024) {
      if (this.state.images) {
        this.refs.grid.style.height = `${this.state.images[0]
          .querySelector("img")
          .getBoundingClientRect().height +
          this.state.images[2].querySelector("img").getBoundingClientRect()
            .height +
          32}px`
      }
    } else {
      this.refs.grid.style.height = `auto`
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.calculateGridHeight()
    if (nextProps.inViewport && nextProps.enterCount === 1) {
      this.state.transitionLoad.play()
    }
  }

  render() {
    const images = this.props.images
    const { active, windowWidth } = this.state
    return (
      <section
        className={[styles.grid, active ? styles[`grid${active}`] : null].join(
          " "
        )}
        ref="grid"
      >
        <div className={[styles.wrapper, "wrapper"].join(" ")}>
          {images &&
            images.map((image, i) => {
              let fluid = false
              let fixed = false

              if (image.src !== null && image.src.localFile) {
                fixed = image.src.localFile.childImageSharp.fixed
                fluid = image.src.localFile.childImageSharp.fluid
              }
              return (
                <div
                  key={i}
                  ref={`item_${i}`}
                  data-ref={i}
                  className={styles.image}
                  onMouseEnter={this.hoverIn}
                  onMouseLeave={this.hoverOut}
                >
                  {windowWidth <= 1024 ? (
                    <picture className={styles.fixed}>
                      <source
                        srcSet={fluid.srcSet}
                        media="(max-width: 1024px)"
                      />
                      <img
                        src={fixed.base64}
                        alt={image.alt}
                        title={image.title}
                      />
                    </picture>
                  ) : (
                    <Image
                      fluid={fluid}
                      alt={image.alt}
                      title={image.title}
                      className={styles.fluid}
                      onLoad={this.handleImageLoaded}
                    />
                  )}
                </div>
              )
            })}
        </div>
      </section>
    )
  }
}

const ImageGridTransition = handleViewport(ImageGrid, {
  threshold: 0,
})
export default ImageGridTransition
